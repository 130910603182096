import React, { Component } from "react";
import { connect } from "react-redux";
import Home from "../Components/home";

import {
  viewAddesByStatus,
  BookTestDrive,
  AddServiceForm,
} from "../../../Client/action";
export class Controller extends Component {
  render() {
    return <Home {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAddesByStatus: () => {
      dispatch(viewAddesByStatus());
    },
    BookTestDrive: (name, email, mobile, modelType, dateTime, address) => {
      dispatch(
        BookTestDrive(name, email, mobile, modelType, dateTime, address)
      );
    },
    AddServiceForm: (
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService
    ) => {
      dispatch(
        AddServiceForm(
          name,
          email,
          mobile,
          model,
          carRegisterationNumber,
          prefferedServiceCenterLocation,
          preferredDateforService
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
