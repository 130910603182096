import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  CardContent,
  Avatar,
  Button,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Rating from "@mui/material/Rating";
const testimonials = [
  {
    quote:
      "I recently purchased the MG Hector, and the experience was absolutely delightful. The features, comfort, and service offered by the dealership were outstanding. Highly recommended!",
    name: "Ravi Kumar",
    avatar: "",
  },
  {
    quote:
      "The MG Astor is a game changer in its segment. I love the AI assistant and the safety features. The service staff were courteous and ensured a seamless buying experience.",
    name: "Sneha Reddy",
    avatar: "",
  },
  {
    quote:
      "I took my MG Gloster for a road trip, and it performed like a beast! The comfort and performance are unmatched. Service centers are also highly professional.",
    name: "Amit Sharma",
    avatar: "",
  },
  {
    quote:
      "Fantastic experience with MG Motors. The ZS EV is a great car, and the electric range exceeded my expectations. Smooth and eco-friendly driving at its best.",
    name: "Priya Das",
    avatar: "",
  },
  {
    quote:
      "MG Motors has set a new benchmark for customer service. My MG Hector Plus is spacious, stylish, and loaded with features. The team ensured I had a smooth delivery experience.",
    name: "Manoj Singh",
    avatar: "",
  },
  {
    quote:
      "I was skeptical about buying the MG Comet EV due to its compact size, but it’s perfect for city drives. Great mileage and very easy to maneuver in traffic!",
    name: "Anjali Gupta",
    avatar: "",
  },
];

const Testimonials = (props) => {
  const [expandedReviews, setExpandedReviews] = useState({}); // Track expanded state for each review
  const visibleCount = 12; // Number of cards to show initially
  const [reviewsToShow, setReviewsToShow] = useState(
    props.client.all_reviews?.slice(0, visibleCount) || []
  );
  useEffect(() => {
    props.fetchReviews();
  }, []);

  const handleLoadMore = () => {
    const newVisibleCount = reviewsToShow.length + visibleCount;
    setReviewsToShow(props.client.all_reviews?.slice(0, newVisibleCount));
  };

  const handleToggleReadMore = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle expansion for specific review
    }));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return {
        truncated: words.slice(0, wordLimit).join(" "),
        remaining: words.slice(wordLimit).join(" "),
      };
    }
    return { truncated: text, remaining: "" };
  };

  return (
    <Box sx={{ paddingBottom: "40px", backgroundColor: "#f9f9f9" }}>
      <Grid
        container
        spacing={4} // Increased spacing for better layout
        justifyContent="center"
        alignItems="stretch"
        sx={{ padding: "20px" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              color: "#d32f2f",
              marginBottom: "20px",
              textTransform: "uppercase",
              fontSize: { xs: "25px", sm: "30px", md: "35px" },
              fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
              textAlign: "center", 
            }}
          >
            MG Testimonials{" "}
            <b style={{ color: "black" }}>See What Our Customers Say</b>
          </Typography>
        </Grid>
        {reviewsToShow?.filter((review) => review.rating >= 4).length > 0 ? (
          reviewsToShow
            ?.filter((review) => review.rating >= 4)
            .map((item, index) => {
              const { truncated, remaining } = truncateText(
                item.text || "",
                50
              );
              const isExpanded = expandedReviews[index];

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4} 
                  key={index}
                  sx={{ display: "flex" }}
                >
                  <Card
                    elevation={3} 
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px",
                      textAlign: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      borderRadius: "8px", 
                    }}
                  >
                    <GoogleIcon
                      fontSize="large"
                      sx={{ color: "#d32f2f", marginBottom: "10px" }} 
                    />
                    <Rating
                      value={item.rating}
                      precision={0.1}
                      readOnly
                      sx={{ marginBottom: "10px" }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        margin: "10px 0",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#333",
                      }}
                    >
                      {item.rating}/5
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        margin: "10px 0",
                        color: "#616161",
                        fontFamily: "LatoGoogle, Lato, sans-serif",
                        textAlign: "justify",
                      }}
                    >
                      {isExpanded
                        ? `${truncated} ${remaining}`
                        : `${truncated}...`}
                    </Typography>
                    {remaining && (
                      <Button
                        onClick={() => handleToggleReadMore(index)}
                        sx={{
                          textTransform: "none",
                          color: "primary.main",
                          padding: 0,
                          fontSize: "12px",
                        }}
                      >
                        {isExpanded ? "Show Less" : "Read More"}
                      </Button>
                    )}
                    <Avatar
                      src={item.profile_photo_url}
                      alt={item.author_name || "User"}
                      sx={{
                        bgcolor: "#E50063",
                        width: 50, 
                        height: 50,
                        fontSize: "16px",
                        margin: "10px 0",
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {item.author_name || "Anonymous"}
                    </Typography>
                  </Card>
                </Grid>
              );
            })
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                color: "#616161",
                textAlign: "center",
                fontStyle: "italic",
                marginTop: "20px",
              }}
            >
              No reviews available.
            </Typography>
          </Grid>
        )}
      </Grid>
      {props.client?.all_reviews?.filter((review) => review.rating >= 4)
        .length >
        reviewsToShow.filter((review) => review.rating >= 4).length && (
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoadMore}
            sx={{
              fontWeight: "bold",
              padding: "10px 20px",
              fontSize: "16px",
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>

    
  );
};

export default Testimonials;
