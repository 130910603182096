import { Component } from "react";
import React from "react";
import "./Windsor.css";
import { Box, Typography } from "@mui/material";
import Navbar from "../../Home/Components/Nav";
import Footer from "../../Home/Components/Footer";
import Windsorcarrousel from "./Windsorcarasole";
import Features from "./Features/Features";
import Accessories from "./Accesories/Accessories";
import Carrosuel from "./cardcarousel";
import Slider from "@mui/material/Slider";
import "bootstrap/dist/css/bootstrap.min.css";
import ismart from "../../images/i-smart.svg";
import sunroof from "../../images/sunroof.svg";
import hd from "../../images/hdscreen.svg";
import system from "../../images/autono.png";
import windsorlogo from "../../images/Windsor/logo.png";
import Contactus from "../../contact/Container/Contactformcon";
import Deets from "../../MgWindsor/Components/deetssection";
import Faq from "../../MgWindsor/Components/faqs";
class Astor extends Component {
  render() {
    return (
      <div id="container">
        <Navbar />
        {/* Banner */}
        <div id="banner-image-windsor">
          <div id="hect-left">
            <div id="hl1">
              {/* <img src={ismart} alt="logo1" />
              <p>
                i-Smart
                <br />
                75+ Connected Car Features
              </p> */}
            </div>
            <div id="hl2">
              {/* <img src={sunroof} alt="logo2" />
              <p>PANORAMIC SUNROOF</p> */}
            </div>
          </div>
          <div id="hect-center">
            <div id="hc1" style={{ marginTop: "85px" }}>
              {/* <img
                src={windsorlogo}
                alt="logo-content"
                height="180px"
                width="450px"
              /> */}
            </div>
            <div id="hc2">
              <Windsorcarrousel />
            </div>
          </div>
          <div id="hect-right">
            <div id="hr1">
              {/* <img src={hd} alt="logo3" />
              <p>
                INFOTAINMENT SYSTEM
                <br />
                India’s Largest Portrait HD screen
              </p> */}
            </div>
            <div id="hr2">
              {/* <img src={system} alt="logo4" />
              <p>AUTONOMOUS LEVEL 2</p> */}
            </div>
          </div>
        </div>

        {/* Features Section */}
        <Features />

        {/* All that Deets */}
        <Box
          id="features"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { md: "0", xs: "0" },
            marginTop: { md: "20px", xs: "0" },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "2.08333vw", xs: "6vw" },
              color: "#cf2230",
              fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
              paddingBottom: { md: "3.125vw", xs: "20px" },
              fontWeight: "400",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            All The Deets
          </Typography>
          <Deets />
        </Box>

        {/* Accessories */}
        <Accessories />

        {/* Cards Carousel Section */}
        <Carrosuel />

        {/* contact us */}
        {/* <Contactus/> */}
        <Contactus someValue="Windsor Ev" />

        {/* image-part1*/}
        <div id="windsorimg1"></div>

        {/* faqs */}
        <Faq />
        {/* Image*/}
        <div id="windsorimg"></div>
        {/* Footer Section */}
        <Footer />
      </div>
    );
  }
}

export default Astor;
