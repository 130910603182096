import React, { Component, createRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import Video1 from "../../Video/video.mp4";
import Box from "@mui/material/Box";
import morris from "../../images/morris.jpg";
import showroom from "../../images/Homeimg/DSC00677.jpg"
import showroomInt from "../../images/Homeimg/DSC00704.jpg";
import Carosel from "../../Home/Components/carasole";
// import mgCar from "../../images/05.png";
// import Navbar from "./Nav";
import Footer from "./Footer";
import { CardImg } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  MenuItem,
  Divider,
  Menu,
  Collapse,
  Select,
  InputLabel,
  TextField,
  Grid,
  Dialog,
  FormControl,
  DialogContent,
  Typography,
} from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { Link } from "react-router-dom";
class Home extends Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.videoSliderRef = React.createRef();
    this.state = {
      videoUrls1: [Video1],
      open: false,
      address: "",
      name: "",
      email: "",
      mobile: "",
      modelType: "",
      dateTime: null,
      emailError: "",
      mobileError: "",
      openTestDrive: false,
      openService: false,
      name1: "",
      email1: "",
      mobile1: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    };
  }

  handleVideoEnd = () => {
    if (this.sliderRef.current) {
      this.sliderRef.current.slickNext();
    }
  };

  handleTestDriveClick = (link) => {
    window.open(link, "_blank");
  };

  componentDidMount() {
    this.props.viewAddesByStatus();
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log(
      this.props.client.addesByStatus,
      " this.props.client.addesByStatus"
    );
  }
  handleClickOpen = () => {
    this.setState({ openTestDrive: true });
  };
  handleServiceClickOpen = () => {
    this.setState({ openService: true });
  };
 
  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard email regex
    return emailRegex.test(email);
  };
  validateMobile = (mobile) => {
    const mobileRegex = /^\+?\d{10,14}$/; // Mobile regex for 10 to 14 digits
    return mobileRegex.test(mobile);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { email, mobile } = this.state;
    let valid = true;
    if (!this.validateEmail(email)) {
      this.setState({ emailError: "Please enter a valid email address" });
      valid = false;
    } else {
      this.setState({ emailError: "" });
    }
    if (!this.validateMobile(mobile)) {
      this.setState({ mobileError: "Please enter a valid mobile number" });
      valid = false;
    } else {
      this.setState({ mobileError: "" });
    }
    if (valid) {
      this.props.BookTestDrive(
        this.state.name,
        this.state.email,
        this.state.mobile,
        this.state.modelType,
        this.state.dateTime,
        this.state.address
      );
      this.setState({
        name: "",
        email: "",
        mobile: "",
        modelType: "",
        dateTime: "",
        address: "",
        openTestDrive: false,
        openService: false,
      });
      // this.handleClose();
    }
  };
  handleSubmit1 = (e) => {
    e.preventDefault();
    const {
      name1,
      email1,
      mobile1,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    this.props.AddServiceForm(
      name1,
      email1,
      mobile1,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService
    );
    this.setState({
      openService: false,
      name1: "",
      email1: "",
      mobile1: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    });
  };
  render() {
    const { videoUrls1, open } = this.state;
    const sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 1000,
      pauseOnHover: false,
    };

    const {
      openTestDrive,
      openService,
      address,
      name,
      email,
      mobile,
      modelType,
      dateTime,
      emailError,
      mobileError,
    } = this.state;

    const uniqueVideoUrls = [...new Set(videoUrls1)];

    const getResponsiveFontSize = () => {
      if (window.innerWidth <= 430) {
        return { textSize: "8px", boldTextSize: "12px" }; // Mobile (small screens)
      } else if (window.innerWidth <= 768) {
        return { textSize: "13px", boldTextSize: "17px" }; // Tablets
      } else if (window.innerWidth <= 1024) {
        return { textSize: "14px", boldTextSize: "22px" }; // Small laptops
      } else {
        return { textSize: "14px", boldTextSize: "25px" }; // Desktops and larger
      }
    };

    const textStyle = {
      fontFamily: "Roboto, sans-serif",
      fontSize: getResponsiveFontSize().textSize,
    };

    const boldTextStyle = {
      fontFamily: "Roboto, sans-serif",
      fontSize: getResponsiveFontSize().boldTextSize,
      fontWeight: "700",
    };

    return (
      <>
        <div className="app-container">
          {/* Nav Section*/}
          {/* <Navbar /> */}

          {/* Video Section */}
          <div className="video-banner-container">
            <Slider ref={this.videoSliderRef} {...sliderSettings}>
              <div className="video-slide">
                <video
                  autoPlay
                  muted
                  controls={false}
                  loop
                  playsInline // Add this for iOS and macOS compatibility
                  className="video"
                  key={uniqueVideoUrls[0]} // Force re-render if URL changes
                  onEnded={() => this.handleVideoEnd()}
                >
                  <source src={uniqueVideoUrls[0]} type="video/mp4" />
                </video>
              </div>
            </Slider>
          </div>

          {/* card part*/}
          <Box
            id="main"
            sx={{
              margin: 0,
              padding: 0,
              display: "flex",
              width: "100%",
              height: { xs: "1300px", md: "540px" },
              overflow: "hidden",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              id="left"
              sx={{
                width: { xs: "100%", sm: "100%", md: "540px" },
                height: { xs: "500px", sm: "760px", md: "540px" },
                display: "flex",
                boxSizing: "border-box",
                "@media (min-width: 1024px) and (max-width: 1139px)": {
                  width: "990px",
                },
              }}
            >
              {this.props.client.addesByStatus?.mediaType === "image" ? (
                <img
                  src={this.props.client.addesByStatus?.image}
                  alt="Car"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    margin: 0,
                    padding: 0,
                    display: "block",
                    border: "none",
                  }}
                />
              ) : (
                <video
                  src={this.props.client.addesByStatus?.image}
                  autoPlay
                  muted
                  loop
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    margin: 0,
                    padding: 0,
                    display: "block",
                    border: "none",
                  }}
                />
              )}
            </Box>
            <Box
                id="right"
                sx={{
                  height: { xs: "320px", md: "500px" },
                  width: { xs: "100%", md: "900px" },
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0,
                  margin: 0,
                }}
              >
                {[
                  { text: "Book a test drive", action: "modal" },
                  { text: "Get Quotations", link: "/pricing" },
                  { text: "Book Service Appointment", action: "modal-service" },
                  { text: "Download Brochure", link: "/brochure" },
                ].map((item, index) => (
                  <Box
                    key={index}
                    id={`right${index + 1}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column", 
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      margin: 0,
                      padding: "8px",
                      fontSize: { xs: "12px", sm: "14px", md: "1.25vw" },
                      letterSpacing: { xs: "0.1px", md: "0.2px" },
                      wordSpacing: { xs: "0.3px", md: "2px" },
                      cursor: "pointer",
                      border: "1px solid transparent",
                      "&:hover": {
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      },
                    }}
                    onClick={(e) => {
                      if (item.action === "modal") {
                        this.handleClickOpen(); // Opens the modal for "Book a test drive"
                      } else if (item.action === "modal-service") {
                        this.handleServiceClickOpen(); // Opens the modal for "Book Service Appointment"
                      }
                    }}
                  >
                    {item.link ? (
                      <Link
                        to={item.link}
                        style={{
                          display: "flex",
                          flexDirection: "column", 
                          alignItems: "center",
                          textDecoration: "none",
                          justifyContent: "center",
                          color: "inherit",
                          width: "100%",
                          height: "100%",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        <i
                          className={`fa-solid fa-${
                            index === 0
                              ? "car"
                              : index === 1
                              ? "quote-left"
                              : index === 2
                              ? "gears"
                              : "book"
                          }`}
                          style={{ marginBottom: "8px", fontSize: "24px" }} 
                        ></i>
                        {item.text}
                      </Link>
                    ) : (
                      <>
                        <i
                          className={`fa-solid fa-${
                            index === 0
                              ? "car"
                              : index === 1
                              ? "quote-left"
                              : index === 2
                              ? "gears"
                              : "book"
                          }`}
                          style={{ marginBottom: "8px", fontSize: "24px" }} 
                        ></i>
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          {item.text}
                        </p>
                      </>
                    )}
                  </Box>
                ))}
            </Box>

          </Box>

          {/* carousel images */}
          <Carosel />

          {/* Content part */}
          <div id="content">
            <div
              className="con"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <p style={textStyle}>
                <b style={boldTextStyle}>
                  MOVING AHEAD WITH A CENTURY-OLD LEGACY!
                </b>
                <br />
                <br />
                When Cecil Kimber founded MG, he could scarcely have imagined
                that his passion for motoring would forge a legacy lasting more
                than a century and stretching across continents. As we steer
                into the future, we remain guided by that same fervor, aspiring
                to craft cars that bring a smile to the faces of those behind
                the wheel. MG’s journey began with a simple yet profound idea:
                to create vehicles that inspire joy and deliver a thrilling
                driving experience. Over the decades, MG has embraced new
                technologies while staying true to its roots. From the classic
                MG T-Series to the modern MG Hector and ZS EV, each model
                reflects a commitment to innovation, quality, and the pure
                pleasure of driving. As we look to the future, we remain
                dedicated to creating vehicles that connect with drivers on a
                personal level, carrying forward the passion and spirit that
                have defined MG for over a century.
              </p>
              <img src={morris} alt="morris" />
            </div>
            <div
              className="con"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <img src={showroomInt} alt="showroom" />
              <p style={textStyle}>
                <b style={boldTextStyle}>THE CENTRAL GROUP</b>
                <br />
                <br />
                The Central Group is a leading dealership group based in Odisha,
                with a robust presence across key locations, including
                Sambalpur, Angul, Jharsuguda, Jajpur, Bhubaneswar, Cuttack,
                Puri, Nayagarh, Berhampur, Ganjam, Phulbani, and Vizag.
                Representing premier automotive brands such as Kia, Honda,
                Renault, Tata Small Commercial Vehicle, MG Motors, and the
                soon-to-open BYD showroom, the Central Group is dedicated to
                offering exceptional vehicles and outstanding customer service.
                Their widespread network and commitment to excellence have
                established them as a trusted name in the automotive industry
                across the region.
              </p>
            </div>
            <div
              className="con"
              style={{ marginTop: "10px", marginBottom: "18px" }}
            >
              <p style={textStyle}>
                <b style={boldTextStyle}>
                  MG CENTRAL ODISHA | PREMIER MG DEALERSHIP NETWORK HECTOR,
                  GLOSTER, ASTOR, ZS EV | SALES & SERVICE
                </b>
                <br />
                <br />
                MG Central, your premier MG dealership in Odisha, is located in
                Khandagiri, Bhubaneswar. We offer an extensive range of MG
                vehicles, including the Hector, Gloster, Astor, and ZS EV,
                catering to diverse needs and preferences. Our state-of-the-art
                service center ensures that your MG receives top-notch care and
                maintenance. Visit MG Central in Khandagiri for exceptional
                deals, expert service, and outstanding customer support.
                Discover the latest MG models and experience unparalleled
                service at MG Central, your trusted MG dealer in Odisha.
              </p>
              <img src={showroom} alt="showroom" />
            </div>
          </div>

          {/* Fotter Upper Part */}
          <div
            id="calc"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              padding: "5px 0",
            }}
          >
            <div
              className="clc"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "180px",
                padding: "5px",
                boxSizing: "border-box",
                
              }}
            >
            <Link to="/pricing" style={{textDecoration:'none', display:'flex',color:'white',gap:'5px',}}>
              <i
                className="fa-solid fa-quote-left"
                style={{ fontSize: "1.05em" }}
              ></i>
              <p style={{ margin: 0, fontSize: "0.8em", fontWeight: "550" }}>
                GET QUOTATIONS
              </p>
              </Link>
            </div>

            <div
              className="clc"
              style={{
                display: "flex",
                alignItems: "center",
                flex: "1 1 auto",
                gap: "5px",
                minWidth: "300px",
                padding: "5px",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
              onClick={() => {
                if (/Mobi|Android/i.test(navigator.userAgent)) {
                  window.location.href = "tel:7941050770";
                } else {
                  alert("Calling is only available on mobile devices.");
                }
              }}
            >
              <i
                className="fa-solid fa-location-pin"
                style={{ fontSize: "1.05em" }}
              ></i>
              <p style={{ margin: 0, fontSize: "0.8em", fontWeight: "550" }}>
                CONNECT WITH MG EXPERT
              </p>
            </div>

            <div
              className="clc"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                minWidth: "180px",
                padding: "5px 10px",
                boxSizing: "border-box",
                cursor: "pointer",
                whiteSpace:'no-wrap'
              }}
            >
              <Link to="/brochure" style={{textDecoration:'none', display:'flex',color:'white',gap:'5px',}}>
              <i
                className="fa-solid fa-book"
                style={{ fontSize: "1.05em" }}
              ></i>
              <p style={{ margin: 0, fontSize: "0.8em", fontWeight: "550",color:'white',textDecoration:'none' }}>
                CAR BROCHURES
              </p>
              </Link>
            </div>
          </div>

          {/*Footer  */}
          <Footer />
        </div>
        {/* Service */}
        <Dialog
          open={this.state.openService}
          onClose={(e) => {
            this.setState({ openService: false });
          }}
          maxWidth="md"
          fullWidth
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              backdropFilter: "blur(5px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "800px",
              }}
            >
              <Typography
                sx={{
                  color: "#D62E31",
                  textAlign: "center",
                  fontSize: { md: "40px", xs: "20px", sm: "35px" },
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  fontWeight: "bolder",
                }}
              >
                Service Appointment Form
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({ openService: false });
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
          <DialogContent>
            <form onSubmit={this.handleSubmit1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    Please fill in the mandatory details for Service
                    Appointment.
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Name"
                      name="name"
                      fullWidth
                      required
                      value={this.state.name1}
                      onChange={(e) => this.setState({ name1: e.target.value })}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      required
                      value={this.state.email1}
                      onChange={(e) =>
                        this.setState({ email1: e.target.value })
                      }
                      error={!!this.state.emailError}
                      helperText={this.state.emailError}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Mobile"
                      name="mobile"
                      type="number"
                      fullWidth
                      required
                      value={this.state.mobile1}
                      onChange={(e) =>
                        this.setState({ mobile1: e.target.value })
                      }
                      error={!!this.state.mobileError}
                      helperText={this.state.mobileError}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="dense" required>
                      <InputLabel
                        sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
                      >
                        Model
                      </InputLabel>
                      <Select
                        name="modelType"
                        value={this.state.model}
                        onChange={(e) =>
                          this.setState({ model: e.target.value })
                        }
                      >
                        <MenuItem value="Hector">MG Hector</MenuItem>
                        <MenuItem value="Astor">MG Astor</MenuItem>
                        <MenuItem value="Gloster">MG Gloster</MenuItem>
                        <MenuItem value="ZsEv">MG ZS EV</MenuItem>
                        <MenuItem value="CometEv">MG Comet EV</MenuItem>
                        <MenuItem value="Windsor">MG Windsor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      label="Car Registration Number"
                      type="text"
                      variant="standard"
                      placeholder="e.g: OD39ZZ5697"
                      name="carRegistrationNumber"
                      value={this.state.carRegisterationNumber}
                      onChange={(e) =>
                        this.setState({
                          carRegisterationNumber: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required variant="standard">
                      <InputLabel
                        sx={{
                          "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                        }}
                      >
                        Preferred Service Center Location
                      </InputLabel>
                      <Select
                        label="Preferred Service Center Location"
                        name="preferredServiceCenterLocation"
                        value={this.state.prefferedServiceCenterLocation}
                        onChange={(e) =>
                          this.setState({
                            prefferedServiceCenterLocation: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="bbsr">Bhubaneswar</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    margin="dense"
                    label="Preferred Date for Service"
                    name="preferredDateForService"
                    type="datetime-local"
                    fullWidth
                    value={this.state.preferredDateforService}
                    onChange={(e) =>
                      this.setState({ preferredDateforService: e.target.value })
                    }
                    InputLabelProps={{
                      shrink: true,
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                    required
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#D23132", fontWeight: "bolder" }}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
        {/* Test Drive */}
        <Dialog
          open={openTestDrive}
          onClose={(e) => {
            this.setState({ openTestDrive: false });
          }}
          maxWidth="md"
          fullWidth
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              backdropFilter: "blur(5px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "800px",
              }}
            >
              <Typography
                sx={{
                  color: "#D62E31",
                  textAlign: "center",
                  fontSize: { md: "40px", xs: "20px", sm: "35px" },
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  fontWeight: "bolder",
                }}
              >
                Book a Test Drive
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50px",
              }}
              onClick={(e) => {
                this.setState({ openTestDrive: false });
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    Please fill in the mandatory details for booking a Test
                    Drive.
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Name"
                      name="name"
                      fullWidth
                      value={name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      required
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      required
                      error={!!emailError}
                      helperText={emailError}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Mobile"
                      name="Mobile"
                      type="number"
                      fullWidth
                      value={mobile}
                      onChange={(e) => {
                        this.setState({ mobile: e.target.value });
                      }}
                      required
                      error={!!mobileError}
                      helperText={mobileError}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="dense" required>
                      <InputLabel
                        sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
                      >
                        Model Type
                      </InputLabel>
                      <Select
                        name="modelType"
                        value={modelType}
                        onChange={(e) => {
                          this.setState({ modelType: e.target.value });
                        }}
                      >
                        <MenuItem value="Hector">MG Hector</MenuItem>
                        <MenuItem value="Astor">MG Astor</MenuItem>
                        <MenuItem value="Gloster">MG Gloster</MenuItem>
                        <MenuItem value="ZsEv">MG ZS EV</MenuItem>
                        <MenuItem value="CometEv">MG Comet EV</MenuItem>
                        <MenuItem value="Windsor">MG Windsor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Test Drive Date & Time"
                      name="date"
                      type="datetime-local"
                      fullWidth
                      value={dateTime}
                      onChange={(e) => {
                        this.setState({ dateTime: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Address"
                      name="address"
                      type="text"
                      fullWidth
                      value={address}
                      onChange={(e) => {
                        this.setState({ address: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#D23132", fontWeight: "bolder" }}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default Home;
