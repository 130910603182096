import React, { Component } from "react";
import { connect } from "react-redux";
import Outlet from "../Component/Outlet";

import { AddServiceForm } from "../../../Client/action";

export class Controller extends Component {
  render() {
    return <Outlet {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    AddServiceForm: (
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService
    ) => {
      dispatch(
        AddServiceForm(
          name,
          email,
          mobile,
          model,
          carRegisterationNumber,
          prefferedServiceCenterLocation,
          preferredDateforService
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
