import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDNYFAPpHhRqbpYFy7moRcytd3HMHt0IRI",
//   authDomain: "mgcentral-7ebfc.firebaseapp.com",
//   projectId: "mgcentral-7ebfc",
//   storageBucket: "mgcentral-7ebfc.appspot.com",
//   messagingSenderId: "756824421135",
//   appId: "1:756824421135:web:9fc62bf186ddc60e1b847a",
//   measurementId: "G-NTH9FPX0F3"
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyDc-OtMzlolQWVKO6Y_J8E_2NrkvOZpXno",
//   authDomain: "mgcentral-21c4e.firebaseapp.com",
//   projectId: "mgcentral-21c4e",
//   storageBucket: "mgcentral-21c4e.firebasestorage.app",
//   messagingSenderId: "681226798683",
//   appId: "1:681226798683:web:d2ddef669be9249f15617e",
//   measurementId: "G-1WGKSPWDE1"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDDB39j8qxH56x11WWocyOR2VeQ7auKB84",
  authDomain: "mgcentral-33fa0.firebaseapp.com",
  projectId: "mgcentral-33fa0",
  storageBucket: "mgcentral-33fa0.firebasestorage.app",
  messagingSenderId: "181376163242",
  appId: "1:181376163242:web:99a2d3240d9dea9b59dfd3",
  measurementId: "G-62EYV316B7"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Analytics and Storage
export const analytics = getAnalytics(app);
export const storage = getStorage(app);










export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CAPTCHA = "CAPTCHA";
export const PASSWORD_GENERATE = "PASSWORD_GENERATE";

export const PROFILEDEATAILS = "PROFILEDEATAILS";
export const EDITPASSWORD = "EDITPASSWORD";

export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const HANDLEDRAWER = "HANDLEDRAWER";
export const SETMOBILE = "SETMOBILE";
export const SET_ALL_ORG = "SET_ALL_ORG";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ORG_DETAILS = "SET_ORG_DETAILS";
export const SET_ORG_USER_DEATILS = "SET_ORG_USER_DEATILS";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_ALL_DATA = "SET_ALL_DATA";
export const SET_USER = "SET_USER";
export const SET_ORG = "SET_ORG";
export const SETLOGINORG = "SETLOGINORG";
export const SET_ORG_USER_DETAILS = "SET_ORG_USER_DETAILS";
export const SET_ORG_DETAILS2 = "SET_ORG_DETAILS2";
export const VIEW_ALL_DEPARTMENT = "VIEW_ALL_DEPARTMENT";
export const VIEW_ALL_PRIORITY = "VIEW_ALL_PRIORITY";
export const VIEW_ALL_STATUS = "VIEW_ALL_STATUS";
export const SET_DOC = "SET_DOC";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_EMAIL = "SET_EMAIL";
export const SET_HOME = "SET_HOME";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_WALET_BY_ID = "SET_WALET_BY_ID";