import { Component } from 'react';
import React from 'react';
import './hector.css';
import {
  Box,
  Typography,
} from '@mui/material';
import Navbar from '../../../../Home/Components/Nav';
import Footer from '../../../../Home/Components/Footer';
import Hectorcarrousel from './Hectorcaraosole'
import Features from './Features/Features';
import Accessories from './Accessories/Accessories';
import Carrosuel from './cardcarousel'
import Slider from '@mui/material/Slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import ismart from '../../../../images/i-smart.svg';
import sunroof from '../../../../images/sunroof.svg';
import hd from '../../../../images/hdscreen.svg';
import system from '../../../../images/autono.png';
import mglogo from '../../../../images/mghectorlogo.jpg';
import Contactus from '../../../../contact/Container/Contactformcon'
// import Pricing from '../../../../MgHector/Components/Hector6seater/Component/CityPricing'
import Deets from '../../../../MgHector/Components/Hector6seater/Component/deetssection';
import Faq from '../../../../MgHector/Components/Hector6seater/Component/faqs'
import calculator from '../../../../images/calc.svg'
class Hector extends Component {
   
  render() {
    return (
      <div id='container'>
        <Navbar />
        {/* Banner */}
        <div id="banner-image-hector6">
          <div id="hect-left">
            <div id="hl1">
              <img src={ismart} alt="logo1"  />
              <p>i-Smart<br/>
             75+ Connected Car Features</p>
            </div>
            <div id="hl2">
              <img src={sunroof} alt="logo2"  />
              <p>PANORAMIC SUNROOF</p>
            </div>
          </div>
          <div id="hect-center">
            <div id="hc1" >
              {/* <img src={mglogo} alt="logo-content" height="180px" width="400px" /> */}
            </div>
            <div id="hectcent62" >
                <Hectorcarrousel/>    
            </div>
          </div>
          <div id="hect-right">
            <div id="hr1">
              <img src={hd} alt="logo3"  />
              <p>INFOTAINMENT SYSTEM<br/>
               India’s Largest Portrait HD screen</p>
            </div>
            <div id="hr2">
              <img src={system} alt="logo4"  />
              <p>AUTONOMOUS LEVEL 2</p>
            </div>
          </div>
        </div>

        {/* Features Section */}
       <Features/>

        {/* All that Deets */}
        <Box
            id="features"
            sx={{
              width: '100%',
              height:'100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: { md: '0', xs: '0' }, 
              marginTop: { md: '20px', xs: '0' },
            }}
          >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { md: '2.08333vw', xs: '6vw' }, 
                  color: '#cf2230',
                  fontFamily: 'GillSansSemiBold, Arial, sans-serif',
                  paddingBottom: { md: '3.125vw', xs: '20px' },
                  fontWeight: '400',
                  textAlign: 'center',
                  marginTop:'10px',
                }}
              >
                All The Deets
              </Typography>
              <Deets />
        </Box>



        {/* Accessories */}
        <Accessories/>

        {/* Cards Carousel Section */}
       <Carrosuel/>

       {/* contact us */}
       {/* <Contactus/> */}
       <Contactus someValue="Hector Plus 6 Seater" />

       {/* calculator */}
            {/* <Box
              sx={{
                height: { md: '68vh', xs: 'auto' }, // Set auto height for small screens
                width: '100%',
                display: 'flex',
                flexDirection: 'column', 
                justifyContent: 'center',
                alignItems: 'center',
                marginTop:{md:'20px',sm:'10px',xs:0},
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                    height:'84vh'
                  },
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: '2.08333vw', xs: '4.4vw' },
                  color: '#cf2230',
                  fontFamily: 'GillSansSemiBold, Arial, sans-serif',
                  paddingBottom: { xs: '5vw', md: '3.125vw' },
                  fontWeight: '400',
                }}
              >
                EMI CALCULATOR
              </Typography>

              <Box
                sx={{
                  height: { md: '58vh', xs: 'auto' },
                  width: { xs: '95%', md: '87%' },
                  boxShadow: '0px 0px 20px grey',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' }, 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  '@media (min-width: 1024px) and (max-width: 1139px)': {
                    flexDirection:'column',
                    height:'75vh'
                  },
                }}
                
              >
                <Box
                  sx={{
                    height: { md: '58vh', xs: 'auto' },
                    width: { xs: '100%', md: '100px' },
                    backgroundColor: '#E32735',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: { xs: '10px', md: 0 },
                    '@media (min-width: 1024px) and (max-width: 1139px)': {
                    width:'100%',
                    height:'130px'
                  },
                  }}
                >
                  <img src={calculator} height='90px' width='80px' alt='calculator' />
                </Box>

                <Box
                  sx={{
                    height: { md: '58vh', xs: 'auto' },
                    width: { xs: '100%', md: '880px' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: { xs: '20px 0', md: '0' }, 
                  }}
                >
                  <Box
                    sx={{
                      height: 'auto', 
                      width: { xs: '100%', md: '850px' },
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: { xs: '20px', md: '30px' },
                      '@media (min-width: 1024px) and (max-width: 1139px)': {
                    marginTop:'-60px',
                  },
                    }}
                  >
                    <Box
                      sx={{
                        height: { md: '35vh', xs: 'auto' }, // Let content size naturally on small screens
                        width: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', marginTop: { xs: '20px', md: 0 } }}>
                        LOAN AMOUNT(₹)
                      </Typography>
                      <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" />
                      <Box sx={{ height: '20px', width: '200px', display: 'flex', color: 'grey', gap: '53px' }}>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>from ₹10K</Typography>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>to ₹13.99L</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        height: { md: '35vh', xs: 'auto' },
                        width: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans' }}>INTEREST RATE (%) P.A</Typography>
                      <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" />
                      <Box sx={{ height: '20px', width: '200px', display: 'flex', color: 'grey', gap: '90px' }}>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>from 7%</Typography>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>to 15%</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        height: { md: '35vh', xs: 'auto' },
                        width: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans' }}>TENURE (YEARS)</Typography>
                      <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" />
                      <Box sx={{ height: '20px', width: '200px', display: 'flex', color: 'grey', gap: '60px' }}>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>from 1 year</Typography>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>to 8 years</Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      height: '22vh', 
                      width: { xs: '100%', md: '850px' },
                      backgroundColor: '#FAFAFA',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'row' }, 
                      justifyContent: 'space-evenly', 
                      alignItems: 'center',
                      padding: { xs: '20px', md: '10px' }, 
                    }}
                  >
                    <Box
                      sx={{
                        height: 'auto',
                        width: '100%', // Full width on small screens
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', fontSize: { xs: '10px' } }}>LOAN AMOUNT</Typography>
                      <Typography sx={{ fontFamily: 'Roboto-Medium', fontWeight: '700', fontSize: { md: '25px', xs: '15px' } }}>
                        ₹10,000
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', fontSize: { xs: '10px' } }}>DOWN PAYMENT</Typography>
                      <Typography sx={{ fontFamily: 'Roboto-Medium', fontWeight: '700', fontSize: { md: '25px', xs: '15px' } }}>
                        ₹50,000
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', fontSize: { xs: '10px' } }}>EMI AMOUNT</Typography>
                      <Typography sx={{ fontFamily: 'Roboto-Medium', fontWeight: '700', fontSize: { md: '25px', xs: '15px' } }}>
                        ₹1,000
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    height: 'auto',
                    width: { xs: '100%', md: '230px' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: { xs: '20px', md: 0 }, // Add margin on smaller screens to separate sections
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'GillSansSemiBold',
                      fontSize: { xs: '4vw', md: '20px' },
                      fontWeight: 'bold',
                    }}
                  >
                    CAR PRICE
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto-Bold',
                      fontSize: { xs: '5vw', md: '24px' },
                      fontWeight: 'bold',
                      marginBottom:{md:'30px',xs:'20px'}
                    }}
                  >
                    ₹13,99,000*
                  </Typography>
                </Box>
              </Box>
            </Box> */}



       {/* faqs */}
          <Faq/>
        {/* Image*/}
        <div id="hector6img"></div>

        {/* Pricing Section */}
        {/* <Pricing/> */}

        <Footer />
      </div>
    );
  }
}

export default Hector;
