import React, { Component } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  Select,
  Dialog,
  DialogContent,
} from "@mui/material";
import showroom from "../Images/DSC01080 (1).JPG";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CallIcon from "@mui/icons-material/Call";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";

const workingHours = [
  { day: "Monday", time: "9:30 AM - 6:30 PM" },
  { day: "Tuesday", time: "9:30 AM - 6:30 PM" },
  { day: "Wednesday", time: "9:30 AM - 6:30 PM" },
  { day: "Thursday", time: "9:30 AM - 6:30 PM" },
  { day: "Friday", time: "9:30 AM - 6:30 PM" },
  { day: "Saturday", time: "9:30 AM - 6:30 PM" },
  { day: "Sunday", time: "Closed" },
];

class OutletSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      openService: false,
      name: "",
      email: "",
      mobile: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleCallClick = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|iphone|ipad|ipod/i.test(userAgent);

    if (isMobile) {
      if (/ipad|tablet/i.test(userAgent)) {
        alert("Please use a mobile device to make the call.");
      } else {
        window.location.href = "tel:9801558387";
      }
    } else {
      alert("This feature is only available on mobile devices.");
    }
  };
  handleGetDirection = () => {
    // Replace with the actual coordinates of your office
    const latitude = 20.25435; // Example: Mg Motors Bhubaneswar latitude
    const longitude = 85.7866; // Example: Mg Motors Bhubaneswar longitude

    // Construct the Google Maps direction URL
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

    // Open Google Maps in a new tab
    window.open(googleMapsUrl, "_blank");
  };
  handleNavigate = (path) => {
    this.props.history.push(path);
  };

  handleServiceClickOpen = () => {
    this.setState({ openService: true });
  };

  handleServiceDialogClose = () => {
    this.setState({ openService: false });
  };

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Add your form submission logic here
  //   console.log("Form Submitted");
  //   this.setState({ openService: false });
  // };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    this.props.AddServiceForm(
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService
    );
    this.setState({
      openService: false,
      name: "",
      email: "",
      mobile: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    });
  };
  render() {
    const {
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    return (
      <Box sx={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontFamily: "GillSansRegular, Arial, sans-serif",
            marginBottom: "20px",
            color: "#CF2230",
            fontWeight: "bold",
            fontSize: { xs: "25px", sm: "30px", md: "35px" },
          }}
        >
          OUR OUTLETS
        </Typography>

        <Card
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            padding: "10px",
            gap: "20px",
          }}
        >
          {/* Left: Image */}
          <Box
            component="img"
            src={showroom}
            alt="Outlet"
            sx={{
              width: { xs: "100%", md: "50%" },
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />

          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontFamily: "GillSansRegular, Arial, sans-serif",
                color: "#CF2230",
                paddingBottom: "10px",
              }}
            >
              MG MOTORS - BHUBANESWAR
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
              <strong>5/5</strong>{" "}
              <span style={{ color: "#FFD700", fontSize: "25px" }}>★★★★★</span>
            </Typography>

            {/* Action Icons */}
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: "10px", gap: { md: "15px", xs: "0px" } }}
            >
              <Grid item xs={6} sm={4} md={2}>
                <Box
                  textAlign="center"
                  // onClick={() => this.handleNavigate("/")}
                  onClick={this.handleGetDirection}
                  style={{ cursor: "pointer" }}
                >
                  <LocationOnIcon fontSize="large" color="error" />
                  <Typography>Navigate to our Service Center</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Box textAlign="center" style={{ cursor: "pointer" }}>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=business.jnine@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "inherit", // Maintain design consistency
                      display: "inline-block",
                    }}
                  >
                    <EmailIcon fontSize="large" color="primary" />
                    <Typography>Email us here</Typography>
                  </a>
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <Box
                  textAlign="center"
                  onClick={() => this.handleNavigate("/contact")}
                  style={{ cursor: "pointer" }}
                >
                  <HelpOutlineIcon fontSize="large" color="warning" />
                  <Typography>For Queries</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Box
                  textAlign="center"
                  onClick={this.handleCallClick}
                  style={{ cursor: "pointer" }}
                >
                  <CallIcon fontSize="large" color="success" />
                  <Typography>For Helpline</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Box textAlign="center">
                  <EventAvailableIcon
                    onClick={this.handleServiceClickOpen}
                    fontSize="large"
                    color="info"
                  />
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={this.handleServiceClickOpen}
                  >
                    Request Service Appointment
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Working Hours Dropdown */}
            <Box sx={{ marginTop: "20px", position: "relative" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#fff",
                  padding: "10px 15px",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                }}
                onClick={this.toggleDropdown}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <AccessTimeIcon /> Working Hours: 9:30 AM - 6:30 PM
                </Typography>
                <IconButton>
                  <ExpandMoreIcon />
                </IconButton>
              </Box>

              {this.state.dropdownOpen && (
                <Card
                  sx={{
                    marginTop: "10px",
                    padding: "10px",
                    backgroundColor: "#fff",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                  }}
                >
                  <Grid container spacing={1}>
                    {workingHours.map((hour, index) => (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <Typography>
                          <strong>{hour.day}:</strong> {hour.time}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              )}
            </Box>
          </Box>
        </Card>

        <Dialog
          open={this.state.openService}
          onClose={this.handleServiceDialogClose}
          maxWidth="md"
          fullWidth
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              backdropFilter: "blur(5px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "800px",
              }}
            >
              <Typography
                sx={{
                  color: "#D62E31",
                  textAlign: "center",
                  fontSize: { md: "40px", xs: "20px", sm: "35px" },
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  fontWeight: "bolder",
                }}
              >
                Service Appointment Form
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => this.setState({ openService: false })}
            >
              <CloseIcon />
            </Box>
          </Box>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    Please fill in the mandatory details for Service
                    Appointment.
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Name"
                      name="name"
                      fullWidth
                      required
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      required
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Mobile"
                      name="mobile"
                      type="number"
                      fullWidth
                      required
                      value={this.state.mobile}
                      onChange={(e) =>
                        this.setState({ mobile: e.target.value })
                      }
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="dense" required>
                      <InputLabel
                        sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
                      >
                        Model
                      </InputLabel>
                      <Select
                        name="model"
                        value={this.state.model}
                        onChange={(e) =>
                          this.setState({ model: e.target.value })
                        }
                      >
                        <MenuItem value="Hector">MG Hector</MenuItem>
                        <MenuItem value="Astor">MG Astor</MenuItem>
                        <MenuItem value="Gloster">MG Gloster</MenuItem>
                        <MenuItem value="ZsEv">MG ZS EV</MenuItem>
                        <MenuItem value="CometEv">MG Comet EV</MenuItem>
                        <MenuItem value="Windsor">MG Windsor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      label="Car Registration Number"
                      type="text"
                      variant="standard"
                      placeholder="e.g: OD39ZZ5697"
                      name="carRegistrationNumber"
                      value={this.state.carRegisterationNumber}
                      onChange={(e) =>
                        this.setState({
                          carRegisterationNumber: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required variant="standard">
                      <InputLabel
                        sx={{
                          "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                        }}
                      >
                        Preferred Service Center Location
                      </InputLabel>
                      <Select
                        name="preferredServiceCenterLocation"
                        value={this.state.prefferedServiceCenterLocation}
                        onChange={(e) =>
                          this.setState({
                            prefferedServiceCenterLocation: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="bbsr">Bhubaneswar</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    margin="dense"
                    label="Preferred Date for Service"
                    name="preferredDateForService"
                    type="datetime-local"
                    fullWidth
                    value={this.state.preferredDateforService}
                    onChange={(e) =>
                      this.setState({ preferredDateforService: e.target.value })
                    }
                    InputLabelProps={{
                      shrink: true,
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                    required
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#D23132", fontWeight: "bolder" }}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
}

export default OutletSection;
