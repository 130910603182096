import React, { Component } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  Drawer,
  MenuItem,
  Divider,
  Menu,
  Collapse,
  Select,
  InputLabel,
  TextField,
  Grid,
  Dialog,
  FormControl,
  DialogContent,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import logoImage from "../../images/mg-icon-003.webp";
import centralLogo from "../../images/download.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
class Nav extends Component {
  state = {
    drawerOpen: false,
    vehicleMenuAnchor: null,
    selectedVehicle: null,
    secondLevelMenuAnchor: null,
    openDropdown: null,
    openVehicleDropdown: {},
    open: false,
    address: "",
    name: "",
    email: "",
    mobile: "",
    modelType: "",
    dateTime: null,
    emailError: "",
    mobileError: "",
    openTestDrive: false,
  };
  navItems = [
    { label: "HOME", path: "/" },
    { label: "VEHICLES", hasDropdown: true },
    { label: "PRE-OWNED CARS", path: "/preownedcar" },
    { label: "ABOUT US", path: "/about" },
    { label: "SERVICE", path: "/service" },
    { label: "CONTACT US", path: "/contact" },
  ];
  vehicleOptions = [
    {
      label: "MG Hector",
      subOptions: [
        { label: "MG Hector", path: "/Hector" },
        { label: "MG Hector Plus 6 Seater", path: "/Hector6seater" },
        { label: "MG Hector Plus 7 Seater", path: "/Hector7seater" },
        { label: "MG Hector Blackstorm", path: "/Hectorblackstorm" },
      ],
    },
    {
      label: "MG Astor",
      subOptions: [{ label: "MG ASTOR", path: "/Astor" }],
    },
    {
      label: "MG GLOSTER",
      subOptions: [
        { label: "MG Gloster", path: "/Gloster" },
        { label: "MG Gloster BlackStorm", path: "/Glosterblackstorm" },
        { label: "MG Gloster Storm Series", path: "/Glosterstorm" },
      ],
    },
    {
      label: "MG ZS EV",
      subOptions: [{ label: "MG ZS EV", path: "/ZsEv" }],
    },
    {
      label: "MG COMET EV",
      subOptions: [{ label: "MG COMET EV", path: "/Comet" }],
    },
    {
      label: "MG WINDSOR EV",
      subOptions: [{ label: "MG Windsor EV", path: "/Windsor" }],
    },
  ];
  handleVehicleMenuOpen = (event) => {
    this.setState({ vehicleMenuAnchor: event.currentTarget });
  };
  handleVehicleMenuClose = () => {
    this.setState({
      vehicleMenuAnchor: null,
      secondLevelMenuAnchor: null,
      selectedVehicle: null,
    });
  };
  handleSecondLevelMenuOpen = (event, vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
      secondLevelMenuAnchor: event.currentTarget,
    });
  };
  handleSecondLevelMenuClose = () => {
    this.setState({ secondLevelMenuAnchor: null, selectedVehicle: null });
  };
  toggleDrawer = (open) => () => {
    this.setState({ drawerOpen: open, openDropdown: null });
  };
  toggleDropdown = (index) => {
    this.setState((prevState) => ({
      openDropdown: prevState.openDropdown === index ? null : index,
    }));
  };
  toggleVehicleDropdown = (vehicleLabel) => {
    this.setState((prevState) => ({
      openVehicleDropdown: {
        ...prevState.openVehicleDropdown,
        [vehicleLabel]: !prevState.openVehicleDropdown[vehicleLabel],
      },
    }));
  };
  handleHomeClick = () => {
    this.setState({ drawerOpen: false, openDropdown: null });
  };
  handleClickOpen = () => {
    this.setState({ openTestDrive: true });
  };
  // handleClose = () => {
  //   this.setState({ open: false });
  // };
  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard email regex
    return emailRegex.test(email);
  };
  validateMobile = (mobile) => {
    const mobileRegex = /^\+?\d{10,14}$/; // Mobile regex for 10 to 14 digits
    return mobileRegex.test(mobile);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { email, mobile } = this.state;
    let valid = true;
    if (!this.validateEmail(email)) {
      this.setState({ emailError: "Please enter a valid email address" });
      valid = false;
    } else {
      this.setState({ emailError: "" });
    }
    if (!this.validateMobile(mobile)) {
      this.setState({ mobileError: "Please enter a valid mobile number" });
      valid = false;
    } else {
      this.setState({ mobileError: "" });
    }
    if (valid) {
      this.props.BookTestDrive(
        this.state.name,
        this.state.email,
        this.state.mobile,
        this.state.modelType,
        this.state.dateTime,
        this.state.address
      );
      this.setState({
        name: "",
        email: "",
        mobile: "",
        modelType: "",
        dateTime: "",
        address: "",
        openTestDrive: false,
      });
      // this.handleClose();
    }
  };
  render() {
    const {
      openTestDrive,
      address,
      name,
      email,
      mobile,
      modelType,
      dateTime,
      emailError,
      mobileError,
    } = this.state;
    const { vehicleMenuAnchor, selectedVehicle, secondLevelMenuAnchor } =
      this.state;
    const { drawerOpen, openDropdown, openVehicleDropdown } = this.state;
    const drawer = (
      <Box
        sx={{
          width: { xs: "100vw", md: "450px" },
          backgroundColor: "rgba(0,0,0,0.9)",
          color: "white",
          height: "100%",
        }}
        role="presentation"
        // onClick={this.toggleDrawer(false)}
        onKeyDown={this.toggleDrawer(false)}
      >
        <Box
          sx={{ padding: 2, textAlign: "center" }}
          onClick={this.handleHomeClick}
        >
          <img
            src={logoImage}
            alt="mg"
            style={{
              width: "140px",
              height: "60px",
            }}
          />
        </Box>
        <Divider sx={{ backgroundColor: "white", margin: "10px 0" }} />
        {/* Drawer navigation items */}
        {this.navItems.map((item, index) => (
          <React.Fragment key={item.label}>
            <MenuItem
              onClick={
                item.hasDropdown ? () => this.toggleDropdown(index) : undefined
              }
              component={item.path ? RouterLink : "div"}
              to={item.path}
              sx={{
                color: "white",
                height: "65px",
                padding: "10px 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Gill Sans, sans-serif",
                letterSpacing: "1.5px",
                fontSize: "0.8rem",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              {item.label}
              {(item.hasDropdown )&&
                (openDropdown === index ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                ))}
            </MenuItem>
            {/* First-level Dropdown for Vehicles */}
            {item.hasDropdown && (
              <Collapse
                in={openDropdown === index}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ paddingLeft: 2 }}>
                  {this.vehicleOptions.map((vehicle) => (
                    <React.Fragment key={vehicle.label}>
                      <MenuItem
                        onClick={
                          vehicle.subOptions
                            ? () => this.toggleVehicleDropdown(vehicle.label)
                            : null
                        }
                        component={vehicle.path ? RouterLink : "div"}
                        to={vehicle.path}
                        sx={{
                          color: "white",
                          padding: "8px 20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontFamily: "Gill Sans, sans-serif",
                          "&:hover": {
                            backgroundColor: "#444",
                          },
                        }}
                      >
                        {vehicle.label}
                        {vehicle.subOptions &&
                          (openVehicleDropdown[vehicle.label] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          ))}
                      </MenuItem>
                      {/* Second-level Dropdown for Vehicle Variants */}
                      {vehicle.subOptions && (
                        <Collapse
                          in={openVehicleDropdown[vehicle.label]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ paddingLeft: 4 }}>
                            {vehicle.subOptions.map((subOption) => (
                              <MenuItem
                                key={subOption.label}
                                component={RouterLink}
                                to={subOption.path}
                                sx={{
                                  color: "white",
                                  padding: "8px 20px",
                                  fontFamily: "Gill Sans, sans-serif",
                                  "&:hover": {
                                    backgroundColor: "#555",
                                  },
                                }}
                              >
                                {subOption.label}
                              </MenuItem>
                            ))}
                          </Box>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </Box>
    );
    return (
      <>
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "rgba(0,0,0)", marginTop: "35px" }}
          // sx={{
          //   backgroundColor: "rgba(0,0,0)",
          //   height: "60px",
          //   width: "100vw",
          //   overflow: "hidden",

          // }}
        >
          <Toolbar
            sx={{
              height: "60px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              margin: 0,
              padding: 0,
            }}
          >
            {/* Menu Button for Mobile */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#000",
                borderRadius: "4px",
                // border: '1px solid #333333',
                width: "50px",
                height: "55px",
              }}
            >
              <IconButton
                edge="start"
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "0.5px",
                }}
                aria-label="menu"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            {/* Logo Section */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                // backgroundColor: '#000',
                borderRadius: "4px",
                width: { md: "250px", lg: "220px" },
                gap: 0,
                width: "220px",
                height: "55px",
                "@media (min-width: 1440px)": {
                  width: "260px",
                },
              }}
            >
              <RouterLink to="/">
              <img
                src={logoImage}
                alt="mg"
                style={{
                  width: "43px",
                  height: "auto",
                  "@media (max-width: 600px)": {
                    width: "40px",
                  },
                }}
              />
              <img
                src={centralLogo}
                alt="central group"
                style={{
                  width: "110px",
                  height: "auto",
                  "@media (max-width: 600px)": {
                    width: "40px",
                  },
                }}
              />
              </RouterLink>
            </Box>
            {/* Desktop Navigation Links */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                gap: "1px",
              }}
            >
              {this.navItems.map((item) => (
                <MenuItem
                  key={item.label}
                  component={item.hasDropdown ? "div" : RouterLink}
                  to={item.path}
                  onClick={
                    item.hasDropdown ? this.handleVehicleMenuOpen : undefined
                  }
                  sx={{
                    color: "#fff",
                    margin: 0,
                    fontFamily: "Gill Sans, sans-serif",
                    fontSize: "10.6719px",
                    height: "55px",
                    width: { md: "147px", lg: "120px" },
                    width: "148.3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    backgroundColor: "#232323",
                    border: "1px solid #333333",
                    transition: "background-color 0.3s, color 0.3s",
                    "&:hover": {
                      backgroundColor: "#333333",
                      textDecoration: "none",
                      cursor: "pointer",
                    },
                    "&:not(:last-child)": {
                      margin: "0px",
                    },
                    "@media (min-width: 1024px) and (max-width: 1139px)": {
                      width: "115px",
                      fontSize: "8px",
                    },
                    "@media (min-width: 1140px)and(max-width: 1339px)": {
                      width: "149px",
                      fontSize: "10.6px",
                    },
                    "@media (min-width: 1440px)": {
                      width: "175px",
                      fontSize: "11px",
                    },
                  }}
                >
                  {item.label}
                  {(item.hasDropdown ) && (
                    <KeyboardArrowDownIcon sx={{ fontSize: "1.2rem", ml: 1 }} />
                  )}
                </MenuItem>
              ))}
              {/* BOOK TEST DRIVE Button */}
              <Button
                onClick={this.handleClickOpen}
                sx={{
                  color: "white",
                  backgroundColor: "#d32f2f",
                  fontFamily: "Gill Sans, sans-serif",
                  letterSpacing: "1.5px",
                  fontSize: "9pt",
                  width: "150px",
                  borderRadius: "8px",
                  padding: "7px",
                  height: "52px",
                  marginLeft: "3px",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                  "@media (min-width: 1024px)  and (max-width: 1139px)": {
                    width: "110px",
                    fontSize: "8px",
                  },
                  "@media (min-width: 1140px)and(max-width: 1339px)": {
                    width: "149px",
                    fontSize: "10.6px",
                  },
                  "@media (min-width: 1440px)": {
                    width: "170px",
                    fontSize: "13px",
                  },
                }}
              >
                BOOK TEST DRIVE
              </Button>
            </Box>
            {/* BOOK TEST DRIVE Button for Mobile */}
            <Button
              onClick={this.handleClickOpen}
              sx={{
                display: { xs: "flex", md: "none" },
                color: "whitesmoke",
                backgroundColor: "#d32f2f",
                fontSize: { xs: "6.7px", sm: "12px" },
                padding: { xs: "7px 10px", sm: "10px 14px" },
                // fontWeight:'900',
                width: { xs: "130px", sm: "150px" },
                marginLeft: "auto",
                fontFamily: "Gill Sans, sans-serif",
                letterSpacing: "1.5px",
                borderRadius: "8px",
                textAlign: "center",
                whiteSpace: "nowrap",
                height: "40px",
                "&:hover": {
                  backgroundColor: "#388e3c",
                },
              }}
            >
              BOOK TEST DRIVE
            </Button>
            {/* First-Level Vehicle Dropdown Menu */}
            <Menu
              anchorEl={vehicleMenuAnchor}
              open={Boolean(vehicleMenuAnchor)}
              onClose={this.handleVehicleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                sx: { backgroundColor: "#000", color: "white" },
              }}
            >
              {this.vehicleOptions.map((vehicle) => (
                <MenuItem
                  key={vehicle.label}
                  onClick={(event) =>
                    vehicle.subOptions
                      ? this.handleSecondLevelMenuOpen(event, vehicle)
                      : this.handleVehicleMenuClose()
                  }
                  sx={{
                    color: "white",
                    fontFamily: "Gill Sans, sans-serif",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                >
                  {vehicle.label}
                </MenuItem>
              ))}
            </Menu>
            {/* Second-Level Dropdown Menu */}
            {selectedVehicle && selectedVehicle.subOptions && (
              <Menu
                anchorEl={secondLevelMenuAnchor}
                open={Boolean(secondLevelMenuAnchor)}
                onClose={this.handleSecondLevelMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{
                  "aria-labelledby": "second-level-menu",
                  sx: { backgroundColor: "#000", color: "white" },
                }}
              >
                {selectedVehicle.subOptions.map((subOption) => (
                  <MenuItem
                    key={subOption.label}
                    component={RouterLink}
                    to={subOption.path}
                    onClick={this.handleSecondLevelMenuClose}
                    sx={{
                      color: "white",
                      fontFamily: "Gill Sans, sans-serif",
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    {subOption.label}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Toolbar>
        </AppBar>
        {/* Drawer for Mobile */}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={this.toggleDrawer(false)}
        >
          {drawer}
        </Drawer>
        <Dialog
          open={openTestDrive}
          onClose={(e) => {
            this.setState({ openTestDrive: false });
          }}
          maxWidth="md"
          fullWidth
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              backdropFilter: "blur(5px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "800px",
              }}
            >
              <Typography
                sx={{
                  color: "#D62E31",
                  textAlign: "center",
                  fontSize: { md: "40px", xs: "20px", sm: "35px" },
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  fontWeight: "bolder",
                }}
              >
                Book a Test Drive
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50px",
                color:'black',
                cursor:'pointer',
              }}
               onClick={(e)=>{
                this.setState({ openTestDrive: false });
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    Please fill in the mandatory details for booking a Test
                    Drive.
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Name"
                      name="name"
                      fullWidth
                      value={name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      required
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      required
                      error={!!emailError}
                      helperText={emailError}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Mobile"
                      name="Mobile"
                      type="number"
                      fullWidth
                      value={mobile}
                      onChange={(e) => {
                        this.setState({ mobile: e.target.value });
                      }}
                      required
                      error={!!mobileError}
                      helperText={mobileError}
                      InputLabelProps={{
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="dense" required>
                      <InputLabel
                        sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
                      >
                        Model Type
                      </InputLabel>
                      <Select
                        name="modelType"
                        value={modelType}
                        onChange={(e) => {
                          this.setState({ modelType: e.target.value });
                        }}
                      >
                        <MenuItem value="Hector">MG Hector</MenuItem>
                        <MenuItem value="Astor">MG Astor</MenuItem>
                        <MenuItem value="Gloster">MG Gloster</MenuItem>
                        <MenuItem value="ZsEv">MG ZS EV</MenuItem>
                        <MenuItem value="CometEv">MG Comet EV</MenuItem>
                        <MenuItem value="Windsor">MG Windsor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Test Drive Date & Time"
                      name="date"
                      type="datetime-local"
                      fullWidth
                      value={dateTime}
                      onChange={(e) => {
                        this.setState({ dateTime: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      label="Address"
                      name="address"
                      type="text"
                      fullWidth
                      value={address}
                      onChange={(e) => {
                        this.setState({ address: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                        sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#D23132", fontWeight: "bolder" }}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default Nav;
