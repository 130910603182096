import React, { Component } from "react";
import { connect } from "react-redux";
import VehicleEnq from "../Components/vehicleEnq";

import {
  viewAllVehicleEnq,
  uploadImaes,
  updateVehicleEnqStatus,
  uploadupdatesImages,
  deleteVehicleEnq,
} from "../action";
export class Controller extends Component {
  render() {
    return <VehicleEnq {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllVehicleEnq: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllVehicleEnq(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },

    updateVehicleEnqStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateVehicleEnqStatus(token, user_id, id, status, page, pageSize));
    },
    deleteVehicleEnq: (token, user_id, id, page, pageSize) => {
      dispatch(deleteVehicleEnq(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
