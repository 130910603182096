import React, { Component } from "react";
import { connect } from "react-redux";
import Contact from "../Components/Contactus";

import { kepInTouch } from "../../../Client/action";

export class Controller extends Component {
  render() {
    return <Contact {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    kepInTouch: (
        firstName,
        lastName,
        mobile,
        email,
        pincode,
        buyDays,
        consentChecked,
        carType
    ) => {
      dispatch(
        kepInTouch(
            firstName,
            lastName,
            mobile,
            email,
            pincode,
            buyDays,
            consentChecked,
            carType

        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
