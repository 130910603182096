import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  DialogContentText,
} from "@mui/material";
import { Eye, EyeOff } from "lucide-react";
import "./login.css";
import centralLogo from "../../Pages/images/download.jpg";
import logoImage from "../../Pages/images/mg-icon-003.webp";
import Snackbar from "../../Common/snackbar/components/snackbar";
import LoaderCon from "../../Common/loader/containers/loader_cont";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: true,
      forget: false,
      showPassword: false,
      email: "",
      password: "",
      token: "",
    };
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { showPassword, forget, email, password } = this.state;
    const { snackbar, close_snack_bar } = this.props;
    return (
      <Grid>
        <Dialog
          open={this.state.form}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: "#000", // Black background
              color: "#fff",
              borderRadius: "10px",
              padding: "20px",
            },
          }}
        >
          <DialogContent>
            <Grid container spacing={2}>
              {/* Logo Container */}
              <Grid
                item
                xs={12}
                alignItems="center"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <img src={centralLogo} alt="Logo" className="logoImage" />
              </Grid>

              {!forget && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="imageContainer imageOverlay"
                  >
                    <img
                      src={logoImage}
                      alt="Car"
                      className="responsiveImage"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} alignContent={"center"}>
                    <Typography
                      variant="h4"
                      textAlign={"center"}
                      style={{ color: "#fff" }}
                    >
                      Login
                    </Typography>
                    <Grid container mt={4}>
                      <Grid item xs={12}>
                        <Typography className="text" style={{ color: "#ccc" }}>
                          Email Id{" "}
                          <span style={{ color: "red", fontSize: "20px" }}>
                            *
                          </span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          InputProps={{
                            style: { backgroundColor: "#333", color: "#fff" },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container mt={2}>
                      <Grid item xs={12}>
                        <Typography className="text" style={{ color: "#ccc" }}>
                          Password
                          <span style={{ color: "red", fontSize: "20px" }}>
                            *
                          </span>
                        </Typography>
                        <FormControl fullWidth>
                          <OutlinedInput
                            type={showPassword ? "text" : "password"}
                            size="small"
                            value={password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <EyeOff color="white" />
                                  ) : (
                                    <Eye color="white" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            onChange={(e) => {
                              this.setState({ password: e.target.value });
                            }}
                            style={{ backgroundColor: "#333", color: "#fff" }}
                          />
                        </FormControl>
                        <Typography
                          mt={1}
                          fontSize={12}
                          color={"primary"}
                          style={{ cursor: "pointer", color: "#ff6347" }}
                          onClick={() => {
                            this.setState({ forget: true });
                          }}
                        >
                          Forgot password?
                        </Typography>
                      </Grid>
                    </Grid>
                    <center>
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: 5,
                          backgroundColor: "#ff0000", // Red color for login button
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#d00000",
                          },
                        }}
                        onClick={() => {
                          this.props.loginFetch(email, password);
                          this.setState({
                            email: "",
                            password: "",
                            token: "",
                          });
                        }}
                      >
                        Login
                      </Button>
                    </center>
                  </Grid>
                </>
              )}
              {forget && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="imageContainer imageOverlay"
                  >
                    <img
                      src={logoImage}
                      alt="Forget"
                      className="responsiveImage"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} alignContent={"center"}>
                    <Typography
                      variant="h4"
                      textAlign={"center"}
                      style={{ color: "#fff" }}
                    >
                      Forget Password
                    </Typography>
                    <DialogContentText
                      color={"#ccc"}
                      mt={3}
                      textAlign={"center"}
                    >
                      Please provide your registered email for the password
                      reset.
                    </DialogContentText>
                    <Grid container mt={4}>
                      <Grid item xs={12}>
                        <Typography className="text" style={{ color: "#ccc" }}>
                          Email Id
                          <span style={{ color: "red", fontSize: "20px" }}>
                            *
                          </span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          InputProps={{
                            style: { backgroundColor: "#333", color: "#fff" },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <center>
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: 5,
                          backgroundColor: "#ff0000", // Red color for submit button
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#d00000",
                          },
                        }}
                        onClick={() => {
                          this.props.forgotpassword(email);
                          this.setState({
                            forget: false,
                            email: "",
                          });
                        }}
                      >
                        Submit
                      </Button>
                    </center>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        <LoaderCon />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid>
    );
  }
}
