import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Drawer from "../Containers/DrawerCont.js";
import Login from "../../Auth/Containers/Login_Cont.js";
import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
import Home from "../../Pages/Home/Container/home.js";
import Hector from "../../Pages/MgHector/Components/Hector/Component/Hector.js";
import Hector6seater from "../../Pages/MgHector/Components/Hector6seater/Component/Hector.js";
import Hector7seater from "../../Pages/MgHector/Components/Hector7seater/Components/Hector.js";
import Hectorblackstorm from "../../Pages/MgHector/Components/Hector Blackstorm/Components/Hectorblackstorm.js";
import Gloster from "../../Pages/MgGloster/Components/Gloster/Component/Gloster.js";
import Glosterblackstorm from "../../Pages/MgGloster/Components/Gloster Blackstorm/Components/Glosterblackstorm.js";
import Glosterseries from "../../Pages/MgGloster/Components/Gloster storm Series/Components/Glosterseries.js";
import Astor from "../../Pages/MgAstor/Components/Astor.js";
import Comet from "../../Pages/MgComet/Components/Comet.js";
import ZsEv from "../../Pages/MgZsEv/Components/zs.js";
import Windsor from "../../Pages/MgWindsor/Components/Windsor.js";
import Car360 from "../../Pages/Home/Components/car360d.js";
import NavBar from "../../Pages/Home/Container/Nav.js";
import Preownedcars from "../../Pages/Preownedcars/Container/preownedCon.js";
import Car1 from "../../Pages/car-details/Container/overviewCon.js";
import Specifications from "../../Pages/car-details/Container/specificationCon.js";
import Clientnav from "../../Client/Containers/NavbarCont.js";
import Advertisement from "../../Client/Containers/advertisementCon.js";
import Oldcar from "../../Client/Containers/oldCarCont.js";
import Admindashboard from "../../Client/Containers/dashboardCon.js";
import EditPage from "../../Client/Containers/addEditCarCon.js";
import View from "../../Client/Containers/ViewpageCon.js";
import Addinginfo from "../../Client/Containers/addOldCarsCon.js";
import Overview from "../../Client/Containers/addOverviewsCon.js";
import Specification from "../../Client/Containers/addSpecificationsCon.js";
import Features from "../../Client/Containers/addFeaturesCon.js";
import Contact from "../../Pages/ContactUs/Container/ContactUsCon.js";
import About from "../../Pages/AboutUs/Components/About.js";
// import Service from '../../Pages/Services/Container/ServiceCon.js'
import Service from "../../Pages/NewService/Component/Service.js";
// import Testimonial from "../../Pages/Testimonial/Component/testimonial.js";
import Headers from "../../Pages/Home/Components/headers.js";
import PricePage from "../../Pages/PricingPage/Components/Pricepagedesign.js";
import Brochure from "../../Pages/Brochures/Component/Brochure.js";
import ContactDetails from "../../Client/Containers/ContactDetailsCon.js";
import TestDrive from "../../Client/Containers/testDriveCon.js";
import Footer from "../../Pages/Home/Components/Footer.js";
import Services from "../../Client/Containers/serviceCon.js";
import VehicleEnq from "../../Client/Containers/vehicleEnqCon.js";
import { Dashboard } from "@mui/icons-material";
class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;
    console.log(login.type);

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route exact path="/client" element={<Login />} />
              <Route
                path="/"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Home />
                  </>
                }
              />
              <Route
                path="/preownedcar"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Preownedcars />
                    <Footer />
                  </>
                }
              />
              {/* <Route
                path="/testimonial"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Testimonial />
                    <Footer />
                  </>
                }
              /> */}
              <Route
                path="/car-details/:id/:brand"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Car1 />
                    <Footer />
                  </>
                }
              />

              <Route
                exact
                path="/Hector"
                element={
                  <>
                    <Headers />
                    <Hector />
                  </>
                }
              />
              <Route
                exact
                path="/Hector6seater"
                element={
                  <>
                    <Headers />
                    <Hector6seater />
                  </>
                }
              />
              <Route
                exact
                path="/Hector7seater"
                element={
                  <>
                    <Headers />
                    <Hector7seater />
                  </>
                }
              />
              <Route
                exact
                path="/Hectorblackstorm"
                element={
                  <>
                    <Headers />
                    <Hectorblackstorm />
                  </>
                }
              />
              <Route
                exact
                path="/Gloster"
                element={
                  <>
                    <Headers />
                    <Gloster />
                  </>
                }
              />
              <Route
                exact
                path="/Glosterblackstorm"
                element={
                  <>
                    <Headers />
                    <Glosterblackstorm />
                  </>
                }
              />
              <Route
                exact
                path="/Glosterstorm"
                element={
                  <>
                    <Headers />
                    <Glosterseries />
                  </>
                }
              />
              <Route
                exact
                path="/Astor"
                element={
                  <>
                    <Headers />
                    <Astor />
                  </>
                }
              />
              <Route
                exact
                path="/Comet"
                element={
                  <>
                    <Headers />
                    <Comet />
                  </>
                }
              />
              <Route
                exact
                path="/contact"
                element={
                  <>
                    <Headers />
                    <Contact />
                  </>
                }
              />
              <Route
                exact
                path="/pricing"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <PricePage />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/ZsEv"
                element={
                  <>
                    <Headers />
                    <ZsEv />
                  </>
                }
              />
              <Route
                exact
                path="/Windsor"
                element={
                  <>
                    <Headers />
                    <Windsor />
                  </>
                }
              />
              <Route
                exact
                path="/car"
                element={
                  <>
                    <Headers />
                    <Car360 />
                  </>
                }
              />
              <Route
                exact
                path="/about"
                element={
                  <>
                    <Headers />
                    <About />
                  </>
                }
              />
              <Route
                exact
                path="/brochure"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Brochure />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/service"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/addinfo"
                element={
                  <>
                    <Clientnav />
                    <Addinginfo />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/overview"
                element={
                  <>
                    <Clientnav />
                    <Overview />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/specification"
                element={
                  <>
                    <Clientnav />
                    <Specification />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/feature"
                element={
                  <>
                    <Clientnav />
                    <Features />
                    <Footer />
                  </>
                }
              />
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          console.log(login.type);
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />
                <Routes>
                  <Route
                    exact
                    path="/client"
                    element={
                      <>
                        <Admindashboard />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/pricing"
                    element={
                      <>
                        <Headers />
                        <NavBar />
                        <PricePage />
                        <Footer />
                      </>
                    }
                  />
                  {/* <Route
                    exact
                    path="/dashboard"
                    element={
                      <>
                        <Admindashboard />
                      </>
                    }
                  /> */}
                  <Route
                    exact
                    path="/advertisement"
                    element={
                      <>
                        <Clientnav /> <Advertisement />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/contact-details"
                    element={
                      <>
                        <Clientnav /> <ContactDetails />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/service-request"
                    element={
                      <>
                        <Clientnav /> <Services />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/vehicle-enquiry"
                    element={
                      <>
                        <Clientnav /> <VehicleEnq />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/oldcar"
                    element={
                      <>
                        <Clientnav /> <Oldcar />
                      </>
                    }
                  />

                  <Route
                    exact
                    path="/book-test-drive"
                    element={
                      <>
                        <Clientnav /> <TestDrive />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/addinfo"
                    element={
                      <>
                        <Clientnav />
                        <Addinginfo />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/overview"
                    element={
                      <>
                        <Clientnav />
                        <Overview />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/specification"
                    element={
                      <>
                        <Clientnav />
                        <Specification />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/feature"
                    element={
                      <>
                        <Clientnav />
                        <Features />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/edit/:id"
                    element={
                      <>
                        <Clientnav /> <EditPage />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/view/:id"
                    element={
                      <>
                        <Clientnav /> <View />
                        <Footer />
                      </>
                    }
                  />

                  <Route exact path="/Profile" element={<Profile />} />

                  <Route exact path="/contact" element={<Contact />} />
                  <Route
                    path="/"
                    element={
                      <>
                        <Headers />
                        <NavBar />
                        <Home />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Hector"
                    element={
                      <>
                        <Headers />
                        <Hector />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Hector6seater"
                    element={
                      <>
                        <Headers />
                        <Hector6seater />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Hector7seater"
                    element={
                      <>
                        <Headers />
                        <Hector7seater />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Hectorblackstorm"
                    element={
                      <>
                        <Headers />
                        <Hectorblackstorm />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Gloster"
                    element={
                      <>
                        <Headers />
                        <Gloster />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Glosterblackstorm"
                    element={
                      <>
                        <Headers />
                        <Glosterblackstorm />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Glosterstorm"
                    element={
                      <>
                        <Headers />
                        <Glosterseries />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Astor"
                    element={
                      <>
                        <Headers />
                        <Astor />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Comet"
                    element={
                      <>
                        <Headers />
                        <Comet />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/ZsEv"
                    element={
                      <>
                        <Headers />
                        <ZsEv />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/Windsor"
                    element={
                      <>
                        <Headers />
                        <Windsor />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/car"
                    element={
                      <>
                        <Headers />
                        <Car360 />
                      </>
                    }
                  />
                </Routes>
              </main>
            </div>
          );
          break;
        case "E":
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>
                  <Route exact path="/Profile" element={<Profile />} />
                </Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>
                  <Route exact path="/client" element={<Login />} />
                </Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
